import React from "react";
import { Box } from "@chakra-ui/core";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import SEO from "../components/SEO";
import { MainHeading } from "../components/headings";
import MainLayout from "../components/layout/main";
import { getAttributeTranslated } from "../util/entities";
import PlaceList from "../components/places/placeList";
import { useRegisterScreen } from "../util/firebaseFunctions"

export default ({ data }) => {
  const intl = useIntl();
  if (data.category.name) {
    useRegisterScreen(data.category.name);
    const name = getAttributeTranslated(data.category, 'name', intl.locale);
    const title = `${intl.formatMessage({ id: 'category.prefix' })} ${name}`;
    const description = `${intl.formatMessage({ id: 'category.description' })} ${name}`;
    return (
      <MainLayout>
        <SEO
          description={description}
          pathname={data.category.fields.slug}
          title={title}
          type="category"
        />
        <Box>
          <MainHeading textAlign="center" mt={8}>
            {title}
          </MainHeading>
          <PlaceList data={data} subtitle />
        </Box>
      </MainLayout>
    );
  }
  return null;
};


export const query = graphql`
  query($id: String!) {
    category(id: {eq: $id }) {
      id
      name
      name_en
      fields {
        slug
      }
    }
    allPlace(filter: {categories: {elemMatch: {id: {eq: $id }}}}) {
      nodes {
        id
        name
        name_en
        membership
        delivery
        photos {
          reference
        }
        fields {
          slug
        }
        categories {
          name
          name_en
          id
          fields {
            slug
          }
        }
      }
    }
  }
`;
